import { createSlice } from "@reduxjs/toolkit";



export const CampaignSlice = createSlice({
    name: 'campaign',
    initialState: {
      campaignData: [],
      campaignList:[],
      totalcampaignlist:[],
      status:'',
    },
    reducers: {
        setCampaignData: (state,action) =>{
            state.campaignData = action.payload
        },
        setCampaignlist:(state,action)=>{
          state.campaignList = action.payload
        },
        totalcampaignlist:(state,action)=>{
          state.totalcampaignlist = action.payload
        }
    },
  
    extraReducers: (builder) => {
    //   builder.addCase(bannerData.fulfilled, (state, action) => {
    //     state.bannerItems = action?.payload?.data?.data;
    //     state.status = 'fulfilled';
    //   }),
    //     builder.addCase(bannerData.pending, (state, action) => {
    //       state.status = 'pending';
    //     });
    //   builder.addCase(festiveBannerData.fulfilled, (state, action) => {
    //     state.festiveBannerItems = action?.payload?.data?.data;
    //     state.status = 'fulfilled';
    //   }),
    //     builder.addCase(festiveBannerData.pending, (state, action) => {
    //       state.status = 'pending';
    //     });
    },
  });

  export const {setCampaignData,setCampaignlist,totalcampaignlist} = CampaignSlice.actions
  
  export default CampaignSlice.reducer;