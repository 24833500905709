import { createSlice } from '@reduxjs/toolkit';
import { GetAllOutcomeListings } from 'store/actions/outcomes-actions';

const initialState = {
    outcomeListing: [],
    outComeListingCount: 0,
};

const outcomeSlice = createSlice({
    name: 'outcome',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(
            GetAllOutcomeListings.fulfilled,
            (state, { payload }) => {
                state.outcomeListing = payload.data;
                state.outComeListingCount = payload.totalItems;
            }
        );
    },
});

export default outcomeSlice.reducer;
