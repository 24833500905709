import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    AUTH_ROLE,
    AUTH_TOKEN,
    AUTH_ID,
    AUTH_NAME,
} from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) || null,
    role: '',
};

export const signIn = createAsyncThunk(
    'auth/login',
    async (data, { rejectWithValue }) => {
        const { email, password } = data;
        const type = 'email';
        try {
            const response = await AuthService.login({ email, password, type });
            console.log('------ response is -------- > ', response);
            const token = response.data.accessToken;
            const role = response.data.user.role;
            const ID = response.data.user._id;
            const name = response.data.user.firstname;

            // Check if the role is "admin" or "vendor" before proceeding
            // if (role !== 'admin') {
            // 	return rejectWithValue('Login restricted for this role');
            // }
            localStorage.setItem(AUTH_NAME, name);
            localStorage.setItem(AUTH_ROLE, role);
            localStorage.setItem(AUTH_TOKEN, token);
            localStorage.setItem(AUTH_ID, ID);

            window.location.reload();
            return token;
        } catch (err) {
            return rejectWithValue('Invalid username / password');
        }
    }
);

export const signUp = createAsyncThunk(
    'auth/register',
    async (data, { rejectWithValue }) => {
        const { email, password } = data;
        try {
            const response = await AuthService.register({ email, password });
            const token = response.data.token;
            localStorage.setItem(AUTH_TOKEN, token);
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (data, { rejectWithValue }) => {
        const { email } = data;
        try {
            const response = await AuthService.forgorPassword({ email });
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const signOut = createAsyncThunk('auth/logout', async () => {
    const response = await FirebaseService.signOutRequest();
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_ROLE);
    localStorage.removeItem(AUTH_ID);
    return response.data;
});

export const signInWithGoogle = createAsyncThunk(
    'auth/signInWithGoogle',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthService.loginInOAuth();
            const token = response.data.token;
            localStorage.setItem(AUTH_TOKEN, token);
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const signInWithFacebook = createAsyncThunk(
    'auth/signInWithFacebook',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthService.loginInOAuth();
            const token = response.data.token;
            localStorage.setItem(AUTH_TOKEN, token);
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = '/';
            state.token = action.payload;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = '';
            state.showMessage = false;
        },
        signOutSuccess: (state) => {
            state.loading = false;
            state.token = null;
            state.redirect = '/';
        },
        showLoading: (state) => {
            state.loading = true;
        },
        signInSuccess: (state, action) => {
            state.loading = false;
            state.token = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                console.log('Login successful payload---', action);
                state.loading = false;
                state.redirect = '/';
                state.token = action.payload;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = '/';
            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = '/';
            })
            .addCase(signUp.pending, (state) => {
                state.loading = true;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = '/';
                state.token = action.payload;
            })
            .addCase(signUp.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signInWithGoogle.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInWithGoogle.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = '/';
                state.token = action.payload;
            })
            .addCase(signInWithGoogle.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signInWithFacebook.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInWithFacebook.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = '/';
                state.token = action.payload;
            })
            .addCase(signInWithFacebook.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            });
    },
});

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    signOutSuccess,
    showLoading,
    signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
