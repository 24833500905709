import { createSlice } from '@reduxjs/toolkit';

export const PatientSlice = createSlice({
    name: 'patient',
    initialState: {
        patientdata: [],
        counsellordata: null,
        campaignlist: [],
        followuplist: [],
        prescriptionid: null,
        patientList: [],
        patientCount: '',
        followupdata: null,
        status: '',
    },
    reducers: {
        setpatientData: (state, action) => {
            state.patientdata = action.payload;
        },
        setcounsellor: (state, action) => {
            state.counsellordata = action.payload;
        },
        setcampaignlist: (state, action) => {
            state.campaignlist = action.payload;
        },
        setfollowuplist: (state, action) => {
            state.followuplist = action.payload;
        },
        setprescriptionid: (state, action) => {
            state.prescriptionid = action.payload;
        },
        setpatientlist: (state, action) => {
            state.patientList = action.payload;
        },
        setPatientCount: (state, action) => {
            state.patientCount = action.payload;
        },
        setfollowupdate: (state, action) => {
            state.followupdata = action.payload;
        },
    },

    extraReducers: (builder) => {
        //   builder.addCase(bannerData.fulfilled, (state, action) => {
        //     state.bannerItems = action?.payload?.data?.data;
        //     state.status = 'fulfilled';
        //   }),
        //     builder.addCase(bannerData.pending, (state, action) => {
        //       state.status = 'pending';
        //     });
        //   builder.addCase(festiveBannerData.fulfilled, (state, action) => {
        //     state.festiveBannerItems = action?.payload?.data?.data;
        //     state.status = 'fulfilled';
        //   }),
        //     builder.addCase(festiveBannerData.pending, (state, action) => {
        //       state.status = 'pending';
        //     });
    },
});

export const {
    setpatientData,
    setcounsellor,
    setcampaignlist,
    setfollowuplist,
    setprescriptionid,
    setpatientlist,
    setPatientCount,
    setfollowupdate,
} = PatientSlice.actions;

export default PatientSlice.reducer;
